import React, { Component } from 'react'
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import "./Infor.css"

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from "firebase/database";

let momo_number = '0945.190.094';
let momo_name = 'Le Huy';

export default class Infor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      momo_number: momo_number,
      momo_name: momo_name
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  async getInfo() {
    // console.log('---Get exchange rate info');
    const firebaseConfig = {
      // The value of `databaseURL` depends on the location of the database
      databaseURL: "https://pinoycash247-b0a8b-default-rtdb.firebaseio.com/",
    };

    const app = initializeApp(firebaseConfig);

    // Initialize Realtime Database and get a reference to the service
    const db = getDatabase(app);

    onValue(ref(db, '/momo-number'), (snapshot) => {
      // console.log('---Momo number firebase: ', snapshot.val());
      momo_number = snapshot.val();
      this.setState({ momo_number: momo_number });
    }, {
      onlyOnce: false
    });

    onValue(ref(db, '/momo-name'), (snapshot) => {
      // console.log('---Momo name firebase: ', snapshot.val());
      momo_name = snapshot.val();
      this.setState({ momo_name: momo_name });
    }, {
      onlyOnce: false
    });
  }

  render() {
    return (
      <Card className="infor_card">
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>Bank Name</th>
              <th>Bank Account</th>
            </tr>
          </thead>
          <tbody>
            
          </tbody>
        </Table>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Name</th>
              <th>E-Wallet name</th>
              <th>E-Wallet number</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.state.momo_name}</td>
              <td>MOMO</td>
              <td>{this.state.momo_number}</td>
            </tr>
           
          </tbody>
        </Table>

        <Card className="infor_card">
          <h6><b>If deposit to MOMO for amount more than 4 mil vnd, you need contact Huy to get Momo number</b></h6>
        </Card>

      </Card>
    )
  }
}

