import React, { Component } from 'react'
import { Card } from 'react-bootstrap'
import "./Notify.css"

export default class Notifi extends Component {
  
  render() {
    return (
      <div>
       
        <Card id="Card_Notify">
          <h6>
            SEND ME A PRIVATE MESSAGE TO GET CHEAPER RATE THAN ANY OTHER AGENCY 
          </h6>

          {/* <h4><u   style={{color: "black"}}><b >Important:</b></u> USD/VND rate exchange currently go up, if my rate a bit lower for you, you can send to
         other agencies, but please come back anytime because other agency might still store a huge amount of peso a few days ago. 
         I have other work so i can wait and i will try my best to make rate cheaper than others soon. Salamat po. I gonna miss you</h4> */}
        </Card>
      </div>
    )
  }
}
