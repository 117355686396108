import './App.css';

import Exchange from './Component/Exchange/Exchange';
import Navigator from './Component/Navigation/Navigator';
import Infor from './Component/Infor/Infor'
import Guide from './Component/Guide/Guide'
import Notifi from './Component/Notify/Notify';
// import Remitcenter from './Component/Remitcenter/Remitcenter'
import React from 'react';


function App() {

  return (
    <div className="App">
      <Navigator />
      <Notifi />
      <Exchange />
      {/* <Remitcenter /> */}
      {/* <Infor /> */}
      <Guide />
    </div>
  );
}

export default App;

