import React, { Component } from "react";
import Card from 'react-bootstrap/Card';
import './Exch.css'

import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from "firebase/database";

let exchange_rate = 445;

const PESOtoVND = function (props) {

  const convert = function (peso) {

    if (peso <= 2000) {
      return peso * exchange_rate + 35000
    }
    if (peso <= 3100) {
      return peso * exchange_rate + 30000
    }
    if (peso <= 4200) {
      return peso * exchange_rate + 27000
    }
    if (peso <= 5300) {
      return peso * exchange_rate + 25000
    }
    if (peso <= 6400) {
      return peso * exchange_rate + 24000
    }
    if (peso >= 20000) {
      return peso * (exchange_rate - 6)
    }
    else {
      return peso * (exchange_rate - 4);
    }
  };
  return (
    <div>
      <span>PESO</span>
      <input
        placeholder="Fill in amount Peso..."
        onChange={(e) => {
          const peso = e.target.value;
          const vnd = convert(peso);
          props.onHandleChange({
            peso,
            vnd,
          });
        }}
        value={props.value}
      />
    </div>
  );
};

const VNDtoPESO = function (props) {
  const convert = function (vnd) {

    if (vnd <= (2000 * exchange_rate + 35000)) {
      return (vnd - 35000) / exchange_rate;
    }
    if (vnd <= (3100 * exchange_rate + 30000)) {
      return (vnd - 30000) / exchange_rate;
    }
    if (vnd <= (4200 * exchange_rate + 27000)) {
      return (vnd - 27000) / exchange_rate;
    }
    if (vnd <= (5300 * exchange_rate + 25000)) {
      return (vnd - 25000) / exchange_rate;
    }
    if (vnd <= (6400 * exchange_rate + 24000)) {
      return (vnd - 24000) / exchange_rate;
    }
    if (vnd >= (exchange_rate * 20000)) {
      return vnd / (exchange_rate - 6)
    }
    else {
      return vnd / (exchange_rate - 4)
    }
  };

  return (
    <div>
      <span>VND </span>
      <input
        placeholder="Fill in amount Vnd..."

        onChange={(e) => {
          const vnd = e.target.value;
          const peso = convert(vnd);
          props.onHandleChange({
            vnd,
            peso,

          });
        }}
        value={props.value}
      />
    </div>
  );
};

export default class Exchange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rate: exchange_rate,
      usd: 0,
      vnd: 0,
    };
  }

  componentDidMount() {
    this.getInfo();
  }

  async getInfo() {
    // console.log('---Get momo info');
    const firebaseConfig = {
      // The value of `databaseURL` depends on the location of the database
      databaseURL: "https://pinoycash247-b0a8b-default-rtdb.firebaseio.com/",
    };

    const app = initializeApp(firebaseConfig);

    // Initialize Realtime Database and get a reference to the service
    const db = getDatabase(app);
    onValue(ref(db, '/exchange-rate'), (snapshot) => {
      // console.log('---Exchange rate firebase: ', snapshot.val());
      exchange_rate = snapshot.val();
      this.setState({ rate: exchange_rate });
    }, {
      onlyOnce: false
    });
  }

  handleChange = (data) => {
    // console.log("Exchange rate: " + exchange_rate);
    this.setState(data);
  };

  render() {
    return (
      <div style={{ margin: "3%" }}>
        <Card className="text-center">
          <Card.Header ><h6>Calculator <b style={{ color: '#2f989e' }}>(Local bank, Gcash)</b></h6></Card.Header>

          <Card.Body>  <h6>Input Peso or VND you want to exchange </h6>
            <PESOtoVND onHandleChange={this.handleChange} value={this.state.peso} /><h4>=(Total amount you receive)</h4>
            <VNDtoPESO onHandleChange={this.handleChange} value={this.state.vnd} />
          </Card.Body>
        </Card>

      </div>
    );
  }
}