import React, { Component } from 'react'
import { Navbar, Container } from 'react-bootstrap'
import { Routes, Route, Link } from 'react-router-dom'
import Guide from "../Guide/Guide"
import './Navi.css'
import { BsFillTelephoneFill } from 'react-icons/bs';

export default class Navigator extends Component {

  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn
    }));
  }

  render() {
    return (
      <div>

        <Navbar variant="flat">
          <Container>
            <Navbar.Brand href="#home">
              <h6 style={{ color: "#2f989e" }}>Instant-PAY
                <b style={{ color: 'red', marginLeft: '20px' }}>       </b>
              </h6>
            </Navbar.Brand>

            <Link to={this.state.isToggleOn ? '/guide' : ''}
            >
            </Link>
          </Container>
          {/* <Button className='button-momo' variant="success"  onClick={this.handleClick}><b>How to deposit Momo</b> </Button> */}
        </Navbar>

        <Routes>
          <Route path="/guide" element={<Guide />} />
        </Routes>

      </div>
    )
  }
}
