import React, { Component } from 'react'
import { Container } from "react-bootstrap"

export default class Guide extends Component {
  render() {
    return (
      <div>
        <Container>
          <h5> How to send</h5>
          <h6>1. Send via bank <br />
            Send to bank and send bill to our social contact.
          </h6>
          {/* <h6>2. Send via Momo <br />
            Go to convenient stories: Circle K, Thegioididong, FPT shop, Gs25...<br />
            Ask Casher to send to our Momo wallet(number and name) and send us receipt, it is free to deposit<br />
            Sometimes, they do not accept the amount bigger than 20mil, you should split the money to our different accounts.
          </h6>
          <h5>Contact us:
            <a href='https://www.facebook.com/hl.huyle.1/'>Facebook</a>
            <a href='https://www.messenger.com/t/100023782014075'>Messenger</a>
          </h5> */}
        </Container>
      </div>
    )
  }
}
